// libs
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useSearchParams, Link, useOutletContext } from "react-router-dom"
import { format } from 'date-fns'

// app
import { fetch, updateSession, update, postToSlack, resetSlackStatus, savePartial, fetchPartial } from './receivingSlice'
import { BETA, access } from '../Account/Permissions'
import { RECEIVING, RECEIVING_PARTIAL, cell, createCodaRow } from "../../helpers/coda-api"
import { slackPostReceiving } from "../../helpers/slack-api"
import { jobIconsRegex } from '../../helpers/utilities'

export function Receiving(props) {

    // remember all URL params are strings
    const params = useParams();
    let [queryParams, setQueryParams] = useSearchParams()

    // coda IDs are alpha-numeric 
    const _receivingId = params.receivingId

    // necessary to pull the user object from higher in the component hierarchy (App.js)
    const _context = useOutletContext()

    // get data from store
    const _apiReceiving = useSelector((state) => state.receiving.api)
    const _apiSlack = useSelector((state) => state.receiving.slack) || { status: "", error: "" }
    const _apiPartial = useSelector((state) => state.receiving.partial)
    const _apiPartialSave = useSelector((state) => state.receiving.partialSave)

    // current receiving slip (need one in session for managing error validation when attempting to submit)
    const _receivingSlipData = useSelector((state) => state.receiving.api.byId[_receivingId])
    const _receivingSlipSession = useSelector((state) => state.receiving.session.byId[_receivingId]) || { api: "" }

    // factory function for creating a coda row object
    const _receivingSlip = createCodaRow(_receivingSlipData)

    // default object for our slack post
    // - params can be overwritten using spread operator: slackPostReceiving({..._slackObject, recBy: "Soleil"})
    // - since we are pre-building our object here, we need to make sure that _receivingSlipData has been populated first
    const _slackObject = _receivingSlipData && {
        codaId: _receivingSlipData.id,
        vendor: _receivingSlip(RECEIVING.vendor),
        summary: _receivingSlip(RECEIVING.summary),
        vendorRef: _receivingSlip(RECEIVING.vendorRef),
        job: _receivingSlip(RECEIVING.job).replace(jobIconsRegex, ""),
        recNotes: _receivingSlip(RECEIVING.recNotes),
        recBy: _receivingSlip(RECEIVING.recBy) || _context.user.name,
        recProblem: false,
        recMissing: '',
        date: format(new Date(_receivingSlip(RECEIVING.date)), "eee, LLL d"),
        by: _receivingSlip(RECEIVING.by)
    }

    // how to dispatch actions
    const dispatch = useDispatch()

    // ------
    // local data
    // ------
    const _isReceived = _receivingSlip(RECEIVING.recDate) && _receivingSlip(RECEIVING.recDate).trim() !== "" ? true : false
    const _partialSorted = _apiPartial.allIds && _apiPartial.allIds.filter(item => { return cell(item, RECEIVING_PARTIAL.receivingLink) === _receivingSlip(RECEIVING.name) })
        .sort((a, b) => { return cell(a, RECEIVING_PARTIAL.recDate) < cell(b, RECEIVING_PARTIAL.recDate) ? 1 : -1 })

    // split list on ";" if exist
    const _receivingUnfiltered = _receivingSlip(RECEIVING.details)
        ? _receivingSlip(RECEIVING.details).match(/;/ig)
            ? _receivingSlip(RECEIVING.details).split(";")
            : _receivingSlip(RECEIVING.details).match(/\n/ig)
                ? _receivingSlip(RECEIVING.details).split(/\n/)
                : [_receivingSlip(RECEIVING.details)]
        : ['All items from acknowledgement were received.']
    // need to clear out empty items in a seperate operation, because filter() creates a new array
    const _receivingList = _receivingUnfiltered.filter(item => { return item.trim() !== "" })

    // Coda calculates all attachments into a singular string separating filenames with a comma
    // !!! IF there is a comma in the attachment name or filename this will break
    const _attachNames = cell(_receivingSlipData, RECEIVING.acknowledgement).split(",")
    const _attachFiles = cell(_receivingSlipData, RECEIVING.fileUrl).split(",")

    const _attachments = _attachFiles[0].trim() !== "" && _attachFiles.map((el, idx) => {
        const extension = _attachFiles[idx].split(".")[_attachFiles[idx].split(".").length - 1]
        return {
            ext: extension,
            filename: _attachFiles[idx],
            name: _attachNames[idx],
            isImg: extension && extension.toLowerCase().match(/(png|jpg|jpeg|gif)/ig),
            isPdf: extension && extension.toLowerCase().match(/(pdf)/ig)
        }
    })


    // ------
    // METHODS
    // ------


    // ------
    // UI
    // ------
    // local state
    const [_uiShowOld, _uiUpdateShowOld] = useState(false)
    const [_uiScrollPos, _uiUpdateScrollPos] = useState(0)


    // ------
    // HANDLERS
    // ------
    const handleSlackPost = (evt, channelName, params) => {
        evt.preventDefault()
        dispatch(postToSlack({
            content: {
                "blocks": slackPostReceiving(_slackObject),
                "text": "Material has been received."
            },
            channelName: channelName ? channelName : 'test'
        }))
    }

    const handleMaterialListToggle = evt => {
        // element attribute "id" begins with "^=" the string "receiving"
        const list = document.querySelectorAll('[id^=receiving]')
        for (let item of list) {
           item.checked = evt.target.checked
        }
    }

    // ------
    // FORM HANDLER
    // ------
    const handleSubmit = evt => {

        evt.preventDefault()
        let errors = []
        let unchecked

        const _problemWithDelivery = evt.target.deliveryIssue.checked

        // validation
        if (!evt.target.deliveryGood.checked && !_problemWithDelivery) errors.push('Must confirm delivery as looking good or not')
        if (_problemWithDelivery && evt.target.comments.value.trim() === '') errors.push('If there is an issue with the delivery, please provide comments')
        if (evt.target.dateReceived.value.trim() === '') errors.push('Must enter a date received')
        if (evt.target.receivedBy.value.trim() === '') errors.push('Delivery must be signed for')
        if (_receivingList.length > 0) {
            unchecked = _receivingList.filter((receiving, idx) => { return !evt.target[`receiving${idx}`].checked })
            if (unchecked.length > 0 && evt.target.deliveryGood.checked) errors.push('If there are missing items, please note there is a problem with the delivery')
        }

        dispatch(updateSession({
            id: _receivingId,
            errors: errors,
            status: errors.length > 0 ? 'rejected' : 'pending',
            data: {
                // even if none checked reply "issue with delivery"
                issueWithDelivery: evt.target.deliveryGood.checked ? false : true,
                comments: evt.target.comments.value.trim(),
                dateReceived: evt.target.dateReceived.value.trim(),
                receivedBy: evt.target.receivedBy.value.trim()
            }
        }))

        if (errors.length == 0) {

            /* 
            If there is a problem with the delivery:
                1. update ONLY the recProblem checkbox in the receiving table
                2. log the details of the receiving (by who, date, notes, etc) in the partial receiving table
                3. post message to slack
            Else:
                1. update all of the details of the receiving (by who, date, notes, etc) in the receiving table
                2. post message to slack
            */

            // problem with delivery determines what gets updated in our receiving table
            const _receivingTableCells = _problemWithDelivery ? [{
                column: RECEIVING.recProblem,
                value: true
            }] :
                [{
                    column: RECEIVING.recDate,
                    value: evt.target.dateReceived.value.trim()
                },
                {
                    column: RECEIVING.recBy,
                    value: evt.target.receivedBy.value.trim()
                },
                {
                    column: RECEIVING.recNotes,
                    value: evt.target.comments.value.trim()
                },

                {
                    column: RECEIVING.recMissing,
                    value: unchecked.length > 0 && unchecked.join('\n')
                }]

            // the first 4 columns are the exact same data as above (but we need diff column IDs)
            const _partialReceivingTableCells = [
                {
                    column: RECEIVING_PARTIAL.recDate,
                    value: evt.target.dateReceived.value.trim()
                },
                {
                    column: RECEIVING_PARTIAL.recBy,
                    value: evt.target.receivedBy.value.trim()
                },
                {
                    column: RECEIVING_PARTIAL.recNotes,
                    value: evt.target.comments.value.trim()
                },
                {
                    column: RECEIVING_PARTIAL.recMissing,
                    value: unchecked.length > 0 && unchecked.join('\n')
                },
                {
                    column: RECEIVING_PARTIAL.receivingLink,
                    value: evt.target.receivingName.value.trim()
                },
                {
                    column: RECEIVING_PARTIAL.origMaterials,
                    value: evt.target.receivingDetails.value.trim()
                }
            ]

            console.log('RECEIVING | dispatch(update)', _receivingId)

            // Whenever you call store.dispatch(action), dispatch will actually return the action as its result.
            // "we can write thunk functions that return a promise, and wait on that promise in our components"
            // https://redux.js.org/tutorials/fundamentals/part-7-standard-patterns#thunks-and-promises
            // --
            // both our `update` and `savePartial` are thunk functions which return promises, so we can chain them with `then()`

            // 1] update receiving table first
            dispatch(update({
                rowId: _receivingId,
                cells: _receivingTableCells
            })).then(action => {

                // 2] update partial table (if applicable)
                if (_problemWithDelivery) {
                    console.log('RECEIVING | dispatch(savePartial)', _receivingId)
                    dispatch(savePartial({ cells: _partialReceivingTableCells })).then(action => {
                        // ---------------- EXACT COPY/PASTE in 2 PLACES ---------------------
                        if ((action.error && error.message === 'Rejected') || (action.type && action.type.slice(-8) === 'rejected')) {
                            // error with Coda update, so we can just stop?
                        } else {
                            // 3] post to Slack
                            dispatch(postToSlack({
                                content: {
                                    "blocks": slackPostReceiving({
                                        ..._slackObject,
                                        recBy: evt.target.receivedBy.value.trim(),
                                        recNotes: evt.target.comments.value.trim(),
                                        recProblem: _problemWithDelivery,
                                        recMissing: unchecked.length > 0 && unchecked.join('\n')
                                    })
                                },
                                channelName: 'receiving'
                            }))
                        }
                        // -------------------------------------------------------------------
                    })
                } else {
                    // ---------------- EXACT COPY/PASTE in 2 PLACES ---------------------
                    if ((action.error && error.message === 'Rejected') || (action.type && action.type.slice(-8) === 'rejected')) {
                        // error with Coda update, so we can just stop?
                    } else {
                        // 3] post to Slack
                        dispatch(postToSlack({
                            content: {
                                "blocks": slackPostReceiving({
                                    ..._slackObject,
                                    recBy: evt.target.receivedBy.value.trim(),
                                    recNotes: evt.target.comments.value.trim(),
                                    recProblem: _problemWithDelivery,
                                    recMissing: unchecked.length > 0 && unchecked.join('\n')
                                })
                            },
                            channelName: 'receiving'
                        }))
                    }
                    // -------------------------------------------------------------------
                }
            })
        }

    }

    // ------
    // HOOKS
    // ------
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {

        // NOTE: if data has not been pulled (ie. !== fulfilled), then fetch
        _apiReceiving.status !== 'fulfilled' && dispatch(fetch())
        _apiPartial.status !== 'fulfilled' && dispatch(fetchPartial())
        _apiSlack.status !== '' && dispatch(resetSlackStatus())

        _uiUpdateScrollPos(queryParams && queryParams.get('scrollPos'))
        _uiUpdateShowOld(queryParams && queryParams.get('showOld'))

        // clear all our query params
        setQueryParams({})

        // We need this here, because the code in `/ui/Page.js` only executes on a page title change 
        window.scrollTo(0, 0)

        // NOTE the [] as the second param, this ensures we only run useEffect() once
    }, []);


    // ------
    // RENDER
    // ------

    const pList = []
    if (_isReceived) pList.push('hiliteMaterial=' + _receivingId)
    if (_isReceived) pList.push('tab=received')
    if (_uiShowOld) pList.push('old=true')
    if (_uiScrollPos > 0) pList.push('scrollY=' + _uiScrollPos)
    const backToListParams = pList.length > 0 ? '?' + pList[0] + (pList.length > 1 ? '&' + pList.slice(1).join("&") : '') : ''

    return (
        <div>

            {/* PENDING receiving */}
            {_apiReceiving.status === 'pending' && <div className="my-0 w-100 alert alert-warning" role="alert"><i className='bi-cloud-arrow-down'></i> Loading <strong>receiving slip</strong>...</div>}

            {/* PENDING partial */}
            {_apiPartial.status === 'pending' && <div className="my-0 w-100 alert alert-warning" role="alert"><i className='bi-cloud-arrow-down'></i> Loading any <strong>received history</strong>...</div>}

            {/* REJECTED session ---------------- */}
            {(_receivingSlipSession.api.status === '' || _receivingSlipSession.api.status === 'rejected') && <div>

                <div className='p-2 bg-light'>
                    <Link
                        className='btn btn-dark btn-sm'
                        to={'/receiving' + backToListParams}
                    > <i className='bi-chevron-left'></i> Back to list</Link>
                </div>

                {_receivingSlipSession.errors.length > 0 &&
                    <div className="mb-0 w-100 alert alert-danger" role="alert">
                        <h4 className="alert-heading"><i className='fs-2 bi-emoji-dizzy'></i> Please fix issues below.</h4>
                        <ul className='mb-0'>{_receivingSlipSession.errors.map((err, idx) => { return <li key={idx}>{err}</li> })}</ul>
                    </div>
                }

                {_receivingSlipSession.api.status === 'rejected' && <div className="mb-0 w-100 alert alert-danger" role="alert"><i className='bi-emoji-dizzy'></i> Unable to save <strong>receiving slip</strong>.{_receivingSlipSession.api.error && <div className="fst-italic mt-2">{_receivingSlipSession.api.error.message}</div>}</div>}

                <div className='bg-white'>

                    <form onSubmit={handleSubmit} className="p-2">

                        {/* we need this data for logging in partially received when there is a problem with the order */}
                        <input type='hidden' name='receivingName' value={_receivingSlip(RECEIVING.name)} />
                        <input type='hidden' name='receivingDetails' value={_receivingSlip(RECEIVING.details)} />

                        {/* TITLE / DESCRIPTION ---------------- */}
                        <div className='mb-2 bg-white'>
                            {_receivingSlip(RECEIVING.recProblem) && !_isReceived && <div className="badge bg-warning">ADDITONAL DELIVERY</div>}
                            <h3 className="fw-bold">{_receivingSlip(RECEIVING.vendor)} {_receivingSlip(RECEIVING.timberCert).match(/(fsc|pefc)/ig) ? <span className="badge bg-danger">{_receivingSlip(RECEIVING.timberCert)}</span> : ""}</h3>
                            <div className='text-dark'>{_receivingSlip(RECEIVING.summary)}</div>
                        </div>

                        {/* PRIMARY DETAILS ---------------- */}
                        <table className="table table-sm table-borderless">
                            <tbody>
                                {_receivingSlip(RECEIVING.vendorRef) && <tr className="border-top">
                                    <td className="label-data">ORDER</td>
                                    <td className={_isReceived ? '' : 'fw-bold'}>{_receivingSlip(RECEIVING.vendorRef)}</td>
                                </tr>}
                                {/* PM is not always present (ie. small jobs) */}
                                {_receivingSlip(RECEIVING.projMgr) && <tr className="border-top">
                                    <td className="label-data w-25">PM</td>
                                    <td>{_receivingSlip(RECEIVING.projMgr)}</td>
                                </tr>}
                                <tr className="border-top">
                                    <td className="label-data">JOB</td>
                                    <td>{_receivingSlip(RECEIVING.job).replace(jobIconsRegex, "")}</td>
                                </tr>

                                {_isReceived && <tr className="border-top">
                                    <td className="label-data bg-light">RECEIVED</td>
                                    <td className='bg-light fw-bold'>{format(new Date(_receivingSlip(RECEIVING.recDate)), "eee, LLL d")} {_receivingSlip(RECEIVING.recProblem) && <span className="badge bg-danger">ISSUE</span> || <i className='bi-check'></i>}</td>
                                </tr>}

                                {_isReceived && <tr className="border-top">
                                    <td className="label-data bg-light">RECEIVED BY</td>
                                    <td className='bg-light'>{_receivingSlip(RECEIVING.recBy)}</td>
                                </tr>}

                                {_isReceived && _receivingSlip(RECEIVING.recNotes) && _receivingSlip(RECEIVING.recNotes).trim() !== "" && <tr className="border-top">
                                    <td className={"label-data bg-light" + (_receivingSlip(RECEIVING.recProblem) ? " text-danger" : "")}>NOTES</td>
                                    <td className={"bg-light fst-italic" + (_receivingSlip(RECEIVING.recProblem) ? " text-danger" : "")}>{_receivingSlip(RECEIVING.recNotes)}</td>
                                </tr>}

                                {_isReceived && _receivingSlip(RECEIVING.recMissing) && _receivingSlip(RECEIVING.recMissing).trim() !== "" && <tr className="border-top">
                                    <td className="label-data bg-light text-danger">MISSING</td>
                                    <td className='fst-italic bg-light text-danger'>
                                        <div className='mb-0'>{_receivingSlip(RECEIVING.recMissing).split("\n").map((item, idx) => {
                                            return <div key={idx}>{item}</div>
                                        })}
                                        </div>
                                    </td>
                                </tr>}
                            </tbody>
                        </table>

                        {/* MATERIAL LIST / ACKNOWLEDGEMENT ---------------- */}
                        <div className="form-check">
                            <input className="form-check-input" onChange={handleMaterialListToggle} type="checkbox" value="" id="receivingListToggle" />
                            <label className="form-check-label" htmlFor="receivingListToggle"><strong>All Material</strong></label>
                        </div>

                        <div className="label-data">Material List</div>
                        <fieldset className="mb-3">

                            {_isReceived || _receivingList
                                .map((item, idx) => {
                                    return <div className="form-check" key={idx}>
                                        <input className="form-check-input" type="checkbox" value="" id={`receiving${idx}`} />
                                        <label className="form-check-label" htmlFor={`receiving${idx}`}>
                                            {item}
                                        </label>
                                    </div>
                                })
                            }

                            {_isReceived && <div>
                                {_receivingList
                                    .map((item, idx) => {
                                        return <div key={idx}>{item}</div>
                                    })}
                            </div>
                            }

                            {_receivingList.length === 0 && <span className='text-muted'>See order acknowledgement.</span>}
                        </fieldset>

                        {_attachments.length > 0 && <table><tbody>
                            {_attachments.map((item, idx) => {
                                return <tr className="border-top" key={idx}>
                                    <td>&nbsp;</td>
                                    <td className='text-break'>
                                        <a href={item.filename}>
                                            {item.isPdf && <i className='bi-file-earmark-pdf'></i>}
                                            {item.name}
                                        </a>
                                    </td>
                                </tr>
                            })}
                        </tbody></table>}



                        {/* INSTRUCTIONS ---------------- */}
                        <div className='label-data'>Instructions</div>
                        <div className="mb-3">
                            {(_receivingSlip(RECEIVING.notes)) || <span className='text-muted'>No instructions given.</span>}
                        </div>

                        {/* FORM CARD ---------------- */}
                        {_isReceived || <div className="card bg-light">
                            <div className="card-body">
                                <fieldset className="mb-1">
                                    <div className="form-check">
                                        <input type="radio" name="radios" className="form-check-input" id="deliveryGood" />
                                        <label className="form-check-label" htmlFor="deliveryGood">All looks good.</label>
                                    </div>
                                    <div className="mb-3 form-check">
                                        <input type="radio" name="radios" className="form-check-input" id="deliveryIssue" />
                                        <label className="form-check-label" htmlFor="deliveryIssue">There is a problem with the delivery.</label>
                                    </div>
                                    <div className="mb-3">
                                        <textarea className="form-control p-2" placeholder="Leave any comments here" id="comments" style={{ height: '100px' }}></textarea>
                                        <div id="comments" className="form-text">Comments are not required.</div>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="dateReceived" className="form-label">Date Received</label>
                                        <input type="date" className="form-control" id="dateReceived" aria-describedby="dateReceived" defaultValue={new Date().toISOString().split('T')[0]} />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="receivedBy" className="form-label">Received By</label>
                                        <input type="text" className="form-control" id="receivedBy" aria-describedby="receivedBy" defaultValue={_context.user.name} />
                                    </div>
                                </fieldset>
                                <div className="d-flex gap-2 justify-content-evenly">
                                    <Link
                                        className='w-100 btn btn-outline-dark'
                                        to='/receiving'
                                    > Cancel
                                    </Link>
                                    <button type="submit" className="w-100 btn btn-primary"><i className="bi-check"></i> Received</button>
                                </div>
                            </div>
                        </div>}
                    </form>

                    {/* PARTIAL HISTORY (if exist) ---------------- */}
                    {_apiPartial.status === 'fulfilled' && _partialSorted.length > 0 && <div className='p-2 mt-2'>
                        <div className='fw-bold text-uppercase mb-2 text-dark'>Received History</div>
                        <table className="table table-sm table-borderless">
                            {_partialSorted
                                .map((item, idx) => {
                                    const _item = createCodaRow(item)
                                    return <tbody key={idx}>
                                        <tr className="border-top">
                                            <td className=''>{format(new Date(_item(RECEIVING_PARTIAL.recDate)), "eee, LLL d")}</td>
                                            <td className='text-muted'>by {_item(RECEIVING_PARTIAL.recBy)}</td>
                                            <td className='label-data text-end'>
                                                <a
                                                    className='text-muted'
                                                    href={item.browserLink + "&view=modal"}
                                                    target="blank"
                                                > {item.id} <i className='bi-box-arrow-up-right'></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr><td colSpan={3} className='fst-italic fw-bold'>{_item(RECEIVING_PARTIAL.recNotes)}</td></tr>
                                        {_item(RECEIVING_PARTIAL.recMissing) && <tr>
                                            <td colSpan={3}>
                                                <div className="label-data">Missing Items</div>
                                                {_item(RECEIVING_PARTIAL.recMissing).split("\n").map((item, idx) => {
                                                    return <div key={idx}>{item}</div>
                                                })}
                                            </td>
                                        </tr>}
                                        <tr><td className='pb-2'>&nbsp;</td></tr>
                                    </tbody>
                                })}
                        </table>
                    </div>}

                    {/* METADATA ---------------- */}
                    <div className='p-2 mt-2'>
                        <div className='fw-bold text-uppercase mb-2 text-dark'>Original Order</div>
                        <table className="table table-sm table-borderless">
                            <tbody>

                                {_receivingSlip(RECEIVING.date) && <tr className="border-top">
                                    <td className="label-data">ORDER DATE</td>
                                    <td>{format(new Date(_receivingSlip(RECEIVING.date)), "eee, LLL d")}</td>
                                </tr>}
                                <tr className="border-top">
                                    <td className="label-data w-25">ORDERED BY</td>
                                    <td>{_receivingSlip(RECEIVING.by)}</td>
                                </tr>
                                <tr className="border-top">
                                    <td className="label-data">EXPECTED</td>
                                    <td>{format(new Date(_receivingSlip(RECEIVING.expected)), "eee, LLL d")}</td>
                                </tr>
                                <tr className="border-top">
                                    <td colSpan={2} className="label-data pt-2">
                                        <a
                                            className='text-muted'
                                            href={_receivingSlipData.browserLink}
                                            target="blank"
                                        > {_receivingSlipData.id}
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>}

            {/* PENDING session ---------------- */
                _receivingSlipSession.api.status === 'pending' && <div className="my-0 w-100 alert alert-warning" role="alert">
                    <h4 className="alert-heading"><i className='bi-cloud-arrow-down'></i> Saving receiving slip...</h4>
                    <p>Completed by <strong>{_receivingSlipSession.data.receivedBy}</strong> on <strong>{_receivingSlipSession.data.dateReceived}</strong>.</p>
                </div>}

            {/* FULFILLED session ---------------- */
                _receivingSlipSession.api.status === 'fulfilled' && <div>
                    <div className="my-0 w-100 alert alert-success" role="alert">
                        <h4 className="alert-heading"><i className='bi-cloud-check'></i> Material received.</h4>
                        <p>Completed by <strong>{_receivingSlipSession.data.receivedBy}</strong> on <strong>{_receivingSlipSession.data.dateReceived}</strong>.</p>
                        <hr />
                        <Link
                            className='btn btn-success'
                            to={'/receiving?receivingIsReceived=true&receivedMaterial=' + _receivingId}
                        > <i className='bi-chevron-left'></i> Back to list</Link>
                    </div>
                </div>
            }

            {/* PENDING partialSave ---------------- */
                _apiPartialSave.status === 'pending' && <div className="my-0 w-100 alert alert-warning" role="alert">
                    <h4 className="alert-heading"><i className='bi-cloud-arrow-down'></i> Saving partial receipt...</h4>
                </div>}

            {/* FULFILLED partialSave ---------------- 
                _apiPartialSave.status === 'fulfilled' && <div className="my-0 w-100 alert alert-success" role="alert">
                    <h4 className="alert-heading"><i className='bi-cloud-check'></i> Material partially received.</h4>
                </div>*/}

            {/* REJECTED partialSave ---------------- */
                _apiPartialSave.status === 'rejected' && <div className="my-0 w-100 alert alert-danger" role="alert">
                    <h4 className="alert-heading"> There was a problem trying to receive a partail delivery, please contact TJ Beatrice.</h4>
                </div>}

            {/* ERROR slack (we dont communicate PENDING or FULFILLED) ------------------ */
                _apiSlack.status === 'rejected' && <div>
                    <div className="my-0 w-100 alert alert-warning" role="alert">
                        <h4 className="alert-heading"><i className='bi-slack'></i> Unable to post to Slack.</h4>
                        <p>This is a Slack issue only and does not affect the delivery from being received.</p>
                        <hr />
                        <pre className='text-break'>{_apiSlack.error.message}</pre>
                    </div>
                </div>
            }

            {access(BETA, _context.user.email) && <div className='p-2 d-flex gap-2 justify-content-evenly'>
                <button type="button" className="w-100 btn btn-secondary" onClick={(evt) => handleSlackPost(evt, 'test', { vendor: 'Test Lumber, Inc', job: 'TEST', recBy: 'Jeff Bezos', recNotes: 'THIS IS A TEST ONLY' })}><i className='bi-slack'></i> Post #bot-tests</button>
                <button type="button" className="w-100 btn btn-secondary" onClick={() => dispatch(resetSlackStatus())}><i className='bi-slack'></i> Reset API state</button>
            </div>}

        </div>
    )
}