// libs
import React from "react"

// smartsheet dates are in the format 'yyyy-mm-dd' and require a timestamp to be used as a valid constructor for JS Date()
const _getDate = (dateString) => {
    return new Date(dateString + "T00:00:00")
}

export const jobIconsRegex = new RegExp("(👷‍♀️|👷‍♂️|🌲|🚚|🦺|🪵|📐|🏷️) ?","ig")


// --- API
export const dateHoriz = (dateString, classes = '') => {
    return <span className={classes}>{_getDate(dateString).toLocaleDateString("en-US", { weekday: 'short', month: 'short', day: 'numeric' })}</span>
}

export const dateStacked = (dateString, classes = '') => {
    return <div className={classes}>
        {_getDate(dateString).toLocaleDateString("en-US", { weekday: 'short' })}<br />
        {_getDate(dateString).toLocaleDateString("en-US", { month: 'short', day: 'numeric' })}
    </div>
}







