// local vs AWS
//export const URL = 'http://localhost:3000/xxxxx'

// SLACK resources
// ---
// https://api.slack.com/apps
// https://app.slack.com/block-kit-builder/


export const slackURL = 'https://yfuhowov04.execute-api.us-east-1.amazonaws.com/api'


// easily retrive channel id from URL in browser: https://app.slack.com/client/T6QL2HQJ3/${CHANNEL_ID}
// can add TTS Bot to channel by trying to message them from within the channel: @TTS bot
export const CHANNEL = {
    test: 'C048DGYMXN2',
    receiving: 'C04BD3PCYTG',
    accounting: 'C04BLTQCQBC'
}

export const slackPostReceiving = ({
    // required params
    codaId, vendor, job, date, by, recBy,
    // optional params
    summary, vendorRef, recNotes, recProblem, recMissing
} = params) => {
    let blocks = [
        {
            "type": "section",
            "text": {
                "type": "mrkdwn",
                "text": ":articulated_lorry: Material has been <https://portal.scpb.com/#/receiving/" + codaId + "|received> by *" + recBy + "*."
            }
        },
        {
            "type": "section",
            "text": {
                "type": "mrkdwn",
                "text": "> *" + vendor + "*"
                    + (summary ? "\n> " + summary : "")
                    + (vendorRef ? "\n> order: *" + vendorRef + "* " : "")
                    + "\n> _" + job + "_"
            }
        },
        { "type": "divider" },
        {
            "type": "context",
            "elements": [
                {
                    "type": "mrkdwn",
                    "text": "ordered: *" + date.toUpperCase() + "* by *" + by.toUpperCase() + "*\n \n"
                }
            ]
        },
        { "type": "divider" }
    ]
    // add receiving notes at specific position (if they exist)
    if (recNotes && !recProblem) blocks.splice(2, 0, {
        "type": "section",
        "text": {
            "type": "mrkdwn",
            "text": "_" + recNotes + "_"
        }
    })
    // add problem with delivery
    if (recProblem) blocks.splice(2, 0, {
        "type": "header",
        "text": {
            "type": "plain_text",
            "text": ":warning: There was a problem with the delivery."
        }
    }, {
        "type": "section",
        "text": {
            "type": "mrkdwn",
            "text": "*PM must update the <https://coda.io/d/TTS-4-2_dXshSxekT0s/Open-Receiving-Notices_suKEl#NOT-received_tuakD|partially received> item.*"
        }
    },{
        "type": "section",
        "text": {
            "type": "mrkdwn",
            "text": "> _" + recNotes + "_"
            + ((recMissing && recMissing.trim() !== '') ? "\n\n*Missing Items*\n" + recMissing : "")
        }
    })
    return blocks
}
